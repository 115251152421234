* {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
    scroll-behavior: smooth;
    font-family: var(--font-family);
}

.container {
    display: flex;
    color: #fff;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: center;
}

.header {
    padding-left: 20px;
}

.title {
    padding-top: 20px;
    padding-bottom: 20px;
}

.description {
    padding-bottom: 20px;
}

.cards {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
}

.card {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: flex-start;
    background-color: #151618;
    max-width: 300px;
    min-width: 300px;
    margin: 20px;
    border-radius: 8px;
    height: 100%;
    line-height: 28px;
    font-size: 1.11rem;
    cursor: pointer;
}

.card:hover {
    box-shadow: 0 50px 50px rgb(0 0 0 / 70%);
    transition: box-shadow .3s ease,transform .3s ease,background-color .3s ease;
}

.description__card {
    display: block;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding: 8px;
    color: #828282;
    text-align: start;
    max-width: 290px;
}

.title__card {
    display: block;
    font-size: 1.5em;
    margin-block-start: 0.83em;
    margin-block-end: 0.83em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-weight: bold;
    padding: 8px;
}